import Container_DBDefaultAreaSkinComponent from '@wix/thunderbolt-elements/src/components/Container/viewer/skinComps/DBDefaultAreaSkin/DBDefaultAreaSkin.skin';


const Container_DBDefaultAreaSkin = {
  component: Container_DBDefaultAreaSkinComponent
};


export const components = {
  ['Container_DBDefaultAreaSkin']: Container_DBDefaultAreaSkin
};


// temporary export
export const version = "1.0.0"
